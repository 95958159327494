// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-code-inspector-js": () => import("./../../../src/pages/code-inspector.js" /* webpackChunkName: "component---src-pages-code-inspector-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-details-js": () => import("./../../../src/pages/news-details.js" /* webpackChunkName: "component---src-pages-news-details-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */)
}

